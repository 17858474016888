import { Sound } from '@pixi/sound';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

// @ts-ignore TODO: fix this
import { clipCanvasSelectorFamily, clipVisibilitySelectorFamily } from '@store/EditSelectors';
// @ts-ignore TODO: fix this
import { playbackMuteAtom, playheadAtom, playheadIsPausedAtom, playheadIsStoppedAtom } from '@store/Timeline';

// @ts-ignore TODO: fix this
import roundToPrecision from '@utils/math/roundToPrecision';

type ClipCanvasData = {
  start: number;
  'asset:src': string;
  'asset:trim': number;
  'asset:volume': number;
};

type CanvasAudioPlayerProps = {
  id: string;
};

type PlayOptions = {
  start: number;
};

function CanvasAudioPlayer({ id }: CanvasAudioPlayerProps) {
  const {
    start,
    ['asset:src']: src,
    ['asset:trim']: trim,
    ['asset:volume']: volume,
  } = useRecoilValue<ClipCanvasData>(clipCanvasSelectorFamily(id));
  const playhead = useRecoilValue<number>(playheadAtom);
  const isPaused = useRecoilValue<boolean>(playheadIsPausedAtom);
  const isStopped = useRecoilValue<boolean>(playheadIsStoppedAtom);
  const isMuted = useRecoilValue<boolean>(playbackMuteAtom);
  const [sound, setSound] = useState<Sound | undefined>();
  const [isSoundReady, setIsSoundReady] = useState(false);
  const visible = useRecoilValue<boolean>(clipVisibilitySelectorFamily(id));

  useEffect(() => {
    try {
      if (!src) {
        throw new Error('No audio source');
      }

      const soundSrc = new URL(src).href;
      const pixiSound = Sound.from({
        url: soundSrc,
        preload: true,
        singleInstance: true,
      });

      setSound(pixiSound);
    } catch (error) {
      console.error(error);
    }

    return () => {
      sound?.destroy();
      setSound(undefined);
      setIsSoundReady(false);
    };
  }, [src]);

  useEffect(() => {
    if (sound?.isLoaded) {
      setIsSoundReady(true);
    }
  }, [sound?.isLoaded]);

  const play = useCallback(
    ({ start: startTime }: PlayOptions) => {
      if (!sound || !isSoundReady) return;
      sound.play({
        start: startTime,
        volume: volume >= 0 && !isMuted ? volume : 0,
      });
    },
    [volume, isMuted, sound, isSoundReady]
  );

  const stop = useCallback(() => {
    if (!sound?.isLoaded || !sound?.isPlaying || isMuted) return;
    sound.stop();
  }, [isMuted, sound]);

  useEffect(() => {
    if (!sound || !sound.isLoaded || !visible || isPaused || isStopped) {
      stop();
      return;
    }

    const soundStart = (trim || 0) + roundToPrecision(playhead - start);
    play({ start: soundStart });
  }, [isMuted, isPaused, isStopped, visible, playhead, start, trim, play, stop, sound]);

  return null;
}

export default CanvasAudioPlayer;
