import '@fontsource/kanit/700.css';
import '@fontsource/nunito-sans';
import '@fontsource/nunito-sans/700.css';
import '@fontsource/nunito-sans/800.css';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

import * as serviceWorker from '@src/serviceWorker';

import App from '@components/app/App.tsx';

const { SENTRY_RELEASE, VITE_STAGE } = import.meta.env;

const allowedOrigins = [
  'https://dashboard-dev.shotstack.io',
  'https://dashboard.shotstack.io',
  'http://localhost:3000',
];

const sentryConfig = {
  dsn: 'https://963a0af050574dfa884ef1564101460b@sentry.io/1852016',
  tracesSampleRate: 1.0,
  beforeSend(event) {
    if (event.request?.url) {
      if (!allowedOrigins.some((origin) => event.request.url.startsWith(origin))) {
        return null; // Drop event if the origin is not allowed
      }
    }
    return event; // Allow the event if no issues
  },
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(),
    }),
  ],
  environment: VITE_STAGE || 'development',
};

if (VITE_STAGE === 'production') {
  if (SENTRY_RELEASE) {
    sentryConfig.release = SENTRY_RELEASE;
  }
} else {
  sentryConfig.debug = true;
}
Sentry.init(sentryConfig);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<App />);

serviceWorker.unregister();
